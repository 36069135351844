import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "./navbar";
import Interface from "./interface";
import Footer from "./footer";
import { titleCase } from "./utils/string-op";


function get_valid_path(path, _default = "calculator") {
  return ["calculator", "resonators", "weapons"].includes(path) ? path : _default;
}


function Main() {
  const navigate = useNavigate();
  let windowPathName = window.location.pathname.replace("/", "");
  let current_path = get_valid_path(windowPathName);
  const [activeSection, setActiveSection] = useState(current_path);
  let state = [activeSection, (section) => {
    let path = get_valid_path(section);
    setActiveSection(path);
    navigate(`/${path}`);
  }]
  if (windowPathName !== current_path) {
    navigate(`/${current_path}`, {"replace" : true});
  }
  document.title = `WuWa Calc - ${titleCase(current_path)}`
  return (
    <div>
      <Navbar state={state}></Navbar>
      <Interface activeSection={activeSection}></Interface>
      <Footer></Footer>
    </div>
  );
}

export default Main;