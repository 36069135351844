const setSection = (props) => {
    let [activeSection, setActiveSection] = props.state;
    let text = props.text.toLowerCase();

    if (activeSection !== text) {
        setActiveSection(text);
    }
}



function NavbarButton(props) {
    let text = props.text.toLowerCase();
    return (
        <div className="navbar-button" onClick={() => {setSection(props)}}>
            {text.toUpperCase()}
        </div>
    );
}


export default NavbarButton;