function Stat(props) {
    return (
        <div className={`stat-${props.name}`}>
            <p className="stat-name">{props.name.toUpperCase()}:</p>
            <p className="stat-value">{props.value}</p>
        </div>
    );
}


export default Stat;