import { Fragment, useState } from "react";
import AbilitySelectOption from "./templates/ability-select-option";
import SliderBar from "./templates/slider-bar";


function AbilityDescription(props) {
    const [selectedAbility, setSelectedAbility] = useState(props?.default || "basic");
    const [abilityLevel, setAbilityLevel] = useState(1);

    let { basic, skill, circuit, liberation, intro, outro } = props.data;
    let [inherent1, inherent2] = props.data.inherent_main;

    const categories = {
        "basic": basic,
        "skill": skill,
        "circuit": circuit,
        "liberation": liberation,
        "intro": intro,
        "outro": outro,
        "inherent": [inherent1, inherent2],
    }

    const setAbility = (name) => {
        if (selectedAbility !== name) {
            setSelectedAbility(name);
        }
    }

    let abilityName, abilityMaxLvl, abilityScalings, abilityDescription, replacementText;

    if (selectedAbility !== "inherent") {
        abilityName = categories[selectedAbility].name;
        abilityMaxLvl = categories[selectedAbility].max_lvl;
        abilityScalings = categories[selectedAbility].scalings;
        abilityDescription = categories[selectedAbility].description;
        replacementText = categories[selectedAbility].replacement;
    } else {
        console.log("inherent");
        abilityName = [inherent1.name, inherent2.name];
        abilityMaxLvl = [inherent1.max_lvl, inherent2.max_lvl];
        abilityDescription = [inherent1.description, inherent2.description];
        replacementText = [inherent1.replacement, inherent2.replacement];
    }

    const formatDescription = (description, replacement) => {
        for (var i = 0; i < replacement.length; i++) {
            description = description.replace(`{${i}}`, replacement[i]);
        }
        return description;
    };
    const adjustInput = (value) =>  {
        let _input = document.getElementById("ability-lvl-input");
        _input.value = value;
        setAbilityLevel(value);
        setSliderbarValue(value);
    }

    let [sliderbarValue, setSliderbarValue] = useState(1);
    let sliderbarFunctions = {
        "onDecrement": adjustInput,
        "onIncrement": adjustInput,
        "onDrag": adjustInput,
    };

    const contents = selectedAbility !== "inherent" ? () => {
        return (
        <Fragment>
            <div class={`${selectedAbility !== "outro" ? "" : "outro-"}skill-container`} change-hover-color="true">
                <div className="skill-name">
                    <p>{abilityName}</p>
                </div>
                <div className="skill-description">
                    <p dangerouslySetInnerHTML={{__html: formatDescription(abilityDescription, replacementText)}}></p>
                </div>
            </div>
                {
                    selectedAbility !== "outro" ? (
                        <div className="skill-scalings-container"  change-hover-color="true">
                            <div className="skill-level-container">
                                <div className="level-text">
                                    <span>Lvl </span>
                                    <input id="ability-lvl-input" type="text" min={1} max={10} step={1} defaultValue={1} onChange={() => {
                                        let _input = document.getElementById("ability-lvl-input");
                                        let _lvl = parseInt(_input.value);
                                        if (isNaN(_lvl)) {
                                            _input.value = "";
                                            return;
                                        }
                                        if (_lvl > 10 || _lvl < 1) {
                                            _lvl = _lvl > 10 ? 10 : 1;
                                        };
                                        setAbilityLevel(_lvl);
                                        setSliderbarValue(_lvl)
                                        _input.value = _lvl;
                                    }}></input>
                                    <span>/{abilityMaxLvl}</span>
                                </div>
                            </div>
                            <div className="skill-sliderbar-container">
                                <SliderBar _id={"resonator-skill-scalings"} min={1} max={10} step={1} default={1} value={sliderbarValue} showButtons={true} functions={{...sliderbarFunctions}}></SliderBar>
                            </div>
                            <table className="scalings-table">
                                {
                                    Object.keys(abilityScalings).map((index) => {
                                        return (
                                            <tr>
                                                <td>{abilityScalings[index].attribute_name}</td>
                                                <td>{formatDescription(abilityScalings[index].description ? abilityScalings[index].description : "{0}", [abilityScalings[index].array[abilityLevel - 1]])}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </table>
                        </div>
                    ) : null
                }
        </Fragment>
    )} : () => {
        return (
            [0, 1].map((index) => {
                return (
                    <Fragment>
                        <div class="inherent-skill-container" change-hover-color="true">
                            <div className="skill-name">
                                <p>{abilityName[index]}</p>
                            </div>
                            <div className="skill-description">
                                <p dangerouslySetInnerHTML={{__html: formatDescription(abilityDescription[index], replacementText[index])}}></p>
                            </div>
                        </div>
                    </Fragment>
                )
            })
    )};

    return (
        <Fragment>
            <div class="skill-category-selector">
                {Object.keys(categories).map((name) => {
                    return <AbilitySelectOption name={name} setAbility={setAbility} selected={name === selectedAbility}></AbilitySelectOption>
                })}
            </div>
            <div class="skill-description-container">
                {contents()}
            </div>
        </Fragment>
    );
}


export default AbilityDescription;