function AbilitySelectOption(props) {
    let { name, selected, setAbility } = props;

    return (
        <div class="ability-select-option" data-selected={selected.toString()} onClick={() => {
            setAbility(name);
        }}>
            <p>{name.toUpperCase()}</p>
        </div>
    );
}


export default AbilitySelectOption;