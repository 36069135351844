import LazyImage from "./templates/lazy-image";
import NavbarButton from "./templates/navbar-button";


const images = require.context("../assets/icons/", false);


function Navbar(props) {
    return (
        <div className="navbar flex-container">
            <div className="navbar-pin-left">
                <LazyImage src={images("./rover-havoc.png")}></LazyImage>
                <h1>Wuthering Waves Damage Calculator</h1>
            </div>
            <div className="flex-container">
                <NavbarButton text="Calculator" state={props.state}></NavbarButton>
                <div className="navbar-spacer">|</div>
                <NavbarButton text="Resonators" state={props.state}></NavbarButton>
                <div className="navbar-spacer">|</div>
                <NavbarButton text="Weapons" state={props.state}></NavbarButton>
            </div>
        </div>
    );
}


export default Navbar;