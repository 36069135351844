function AscensionStars(props) {
    let showAmount = props.showAmount || 0;
    return (
        <div className="ascension-stars">
            {[...Array(showAmount).keys()].map(() => {
                return <div className="ascension-fill"></div>
            })}
            {[...Array(6 - showAmount).keys()].map(() => {
                return <div></div>
            })}
        </div>
    );
}


export default AscensionStars;