import { useState } from "react";

import { titleCase } from "./utils/string-op";
import resonators from "../assets/json/resonators.json";
import ResonatorSelectOption from "./templates/resonator-select-option";
import LazyImage from "./templates/lazy-image";
import Stat from "./templates/stat";
import SliderBar from "./templates/slider-bar";
import AscensionStars from "./templates/ascension-stars";
import AbilityDescription from "./ability-description";


const images = require.context("../assets/icons/", false);
const ascensionRanges = [
    [1, 20],    // 0
    [20, 40],   // 1
    [40, 50],   // 2
    [50, 60],   // 3
    [60, 70],   // 4
    [70, 80],   // 5
    [80, 90],   // 6
]
const resonatorList = Object.keys(resonators);
const statList = ["hp", "atk", "def"];

function ResonatorInfo() {
    let [selectedResonator, setSelectedResonator] = useState("rover-havoc");
    let [lvl, _setLvl] = useState(1);
    const setLvl = (_lvl) => {
        if (1 <= parseInt(_lvl) <= 90) {
            _setLvl(_lvl);
        }
    };
    let [ascension_lvl, _setAscensionLvl] = useState(0);
    const setAscensionLvl = (_ascension_lvl) => {
        if (0 <= parseInt(_ascension_lvl) <= 6) {
            _setAscensionLvl(_ascension_lvl);
        }
    };
    const adjustInput = (value) =>  {
        let _input = document.getElementById("lvl-input");
        let _lvl = ascensionRanges[Math.floor(value / 2)][value % 2 ? 1 : 0];
        _input.value = _lvl;
        setLvl(_lvl);
        setAscensionLvl(Math.floor(value / 2));
        setSliderbarValue(value);
    }
    const adjustSlider = (value) => {
        let item = ascensionRanges.filter((_item) => {
            return _item[0] <= value && value <= _item[1];
        })[0];
        let index = ascensionRanges.indexOf(item);
        setSliderbarValue(index * 2 + (item[1] === value ? 1 : 0))
        setAscensionLvl(index);
    }
    
    let _element = resonators[selectedResonator]['element'];
    let _weaponType = resonators[selectedResonator]["weapon_type"];

    let [sliderbarValue, setSliderbarValue] = useState(0);
    let sliderbarFunctions = {
        "onDecrement": adjustInput,
        "onIncrement": adjustInput,
        "onDrag": adjustInput,
    };

    return (
        <div className="resonator-kit-info">
            <div className="selector">
                {resonatorList.map((name) => {
                    return <ResonatorSelectOption resonator={name} data={resonators[name]} images={images} selected={name === selectedResonator} set={setSelectedResonator}></ResonatorSelectOption>
                })}
            </div>
            <div className="abilities">
                <div className="resonator-base-description" change-hover-color="true">
                    <div className="icons">
                        <div className="resonator-icon-small">
                            <LazyImage src={images(`./${selectedResonator}.png`)} alt={selectedResonator}></LazyImage>
                        </div>
                    </div>
                    <div className="resonator-base-stats">
                        <div className="stats-adjuster">
                            <div className="name-element-weapon-container">
                                <h2>{titleCase(selectedResonator, "-")}</h2>
                                <div className="element-weapon-container">
                                    <LazyImage src={images(`./element-${_element}.png`)} alt={_element}></LazyImage>
                                    <LazyImage src={images(`./generic-weapon-${_weaponType}.png`)} alt={_weaponType}></LazyImage>
                                </div>
                            </div>
                            <div className="level-container">
                                <AscensionStars showAmount={ascension_lvl}></AscensionStars>
                                <div className="level-text">
                                    <span>Lvl </span>
                                    <input id="lvl-input" type="text" min={1} max={90} step={1} defaultValue={1} onChange={() => {
                                        let _input = document.getElementById("lvl-input");
                                        let _lvl = parseInt(_input.value);
                                        if (isNaN(_lvl)) {
                                            _input.value = "";
                                            return;
                                        }
                                        if (_lvl > 90 || _lvl < 1) {
                                            _lvl = _lvl > 90 ? 90 : 1;
                                        };
                                        setLvl(_lvl);
                                        adjustSlider(_lvl);
                                        _input.value = _lvl;
                                    }}></input>
                                    <span>/{ascensionRanges[ascension_lvl][1]}</span>
                                </div>
                                <SliderBar _id={"resonator-resonatorLevel"} min={0} max={13} step={1} default={0} value={sliderbarValue} showButtons={true} functions={{...sliderbarFunctions}}></SliderBar>
                            </div>
                        </div>
                        <div className="stats-display">
                            {statList.map((stat) => {
                                return <Stat name={stat} value={resonators[selectedResonator]["stats"][lvl + ascension_lvl - 1][stat]}></Stat>
                            })}
                        </div>
                    </div>
                </div>
                <div className="resonator-abilities-description" change-hover-color="true">
                    <AbilityDescription data={resonators[selectedResonator]}></AbilityDescription>
                </div>
            </div>
        </div>
    );
}


export default ResonatorInfo;