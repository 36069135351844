function Footer() {
    return (
        <div className="footer">
            <div className="flex-container">
                <p>Developed by Marwynn Somridhivej</p>
                <p>Discord: MS Arranges#3060</p>
                <p>Game assets are property of Kuro Games</p>
            </div>
        </div>
    );
}


export default Footer;