import { Fragment, useEffect } from "react";
import { ReactComponent as SliderDecrementSVG} from "../../assets/icons/slider-button-decrement.svg";
import { ReactComponent as SliderIncrementSVG} from "../../assets/icons/slider-button-increment.svg";


const dragDB = {}
let currentlyDragging = null;


function SliderBar(props) {
    let { min, max, step } = props;
    let { onIncrement, onDecrement, onDrag } = props.functions;
    let value = props?.value || props.default;
    let base = (max - min) / step;
    let progress = (value - min) / step;
    let p_percent = 100 * (progress / base);

    if (dragDB[props._id] === undefined) {
        dragDB[props._id] = false;
    }


    const onMouseUpFunction = (e) => {
        dragDB[props._id] = false
        currentlyDragging = null;
    };
    const onMouseMoveFunction = (e) => {
        if (dragDB[props._id] && currentlyDragging === props._id) {
            let leftBound = parseInt(document.querySelector(`div#slider-bar-left-bound[data-id=${props._id}]`).getBoundingClientRect().left.toFixed(0));
            let rightBound = parseInt(document.querySelector(`div#slider-bar-right-bound[data-id=${props._id}]`).getBoundingClientRect().right.toFixed(0));
            let width = rightBound - leftBound;
            let interval = parseInt((width / base).toFixed(0));
            let normLocation = parseInt(((interval * progress) + leftBound).toFixed(0))
            let clientX = parseInt(e.clientX.toFixed(0));
            if (clientX < leftBound) {
                if (value !== min) {
                    onDrag(min);
                }
            } else if (clientX > rightBound) {
                if (value !== max) {
                    onDrag(max);
                }
            } else {
                if (normLocation + interval < clientX) {
                    onDrag(value + step);
                } else if (normLocation - interval > clientX) {
                    onDrag(value - step)
                }
            }
        }
    };
    const mouseFunctions = {
        "mouseup": onMouseUpFunction,
        "mousemove": onMouseMoveFunction,
    }

    useEffect(() => {
        for (const eventName in mouseFunctions) {
            document.addEventListener(eventName, mouseFunctions[eventName], {});
        }
        return () => {
            for (const eventName in mouseFunctions) {
                document.removeEventListener(eventName, mouseFunctions[eventName], {});
            }
        }
    })

    let sliderComponent = (
        <div className="slider-bar">
            <div className="slider-bar-progress" id="slider-bar-left-bound" data-id={props._id} style={{width: `${p_percent}%`}}></div>
            <div className="slider-bar-handle"
                style={{left: `calc(${p_percent}% - 7px)`}}
                onMouseDown={(e) => {
                    dragDB[props._id] = true;
                    currentlyDragging = props._id
                }}
            ></div>
            <div className="slider-bar-remaining" id="slider-bar-right-bound" data-id={props._id} style={{width: `${100 - p_percent}%`}}></div>
        </div>
    );
    if (props.showButtons) {
        sliderComponent = (
            <Fragment>
                <div className="slider-button" id="slider-decrement" onClick={() => {
                    if (value > min) {
                        onDecrement(value - 1);
                    }
                }}>
                    <SliderDecrementSVG></SliderDecrementSVG>
                </div>
                {sliderComponent}
                <div className="slider-button" id="slider-increment" onClick={() => {
                    if (value < max) {
                        onIncrement(value + 1);
                    }
                }}>
                    <SliderIncrementSVG></SliderIncrementSVG>
                </div>
            </Fragment>
        )
    }
    return (
        <div className="slider-container">
            {sliderComponent}
        </div>
    );
}


export default SliderBar;