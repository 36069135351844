import LazyImage from "./lazy-image";


function ResonatorSelectOption(props) {
    const displayText = props.resonator.toUpperCase();
    const oversized = displayText.length >= 10 ? "true" : "false"

    return (
        <div className="resonator-option" onClick={() => {props.set(props.resonator)}} data-selected={props.selected.toString()}>
            <LazyImage src={props.images(`./${props.resonator}.png`)} alt={props.resonator}></LazyImage>
            <div className="name-container">
                <p oversized={oversized}>{displayText}</p>
            </div>
        </div>
    );
}


export default ResonatorSelectOption;