import SidebarMenu from "./sidebar";
import ResonatorInfo from "./resonator-info";
import WeaponInfo from "./weapon-info";


function Interface(props) { // accepts "state" obj from main
    let section;
    switch (props.activeSection) {
        case "calculator":
            section = <SidebarMenu></SidebarMenu>;
            break
        case "resonators":
            section = <ResonatorInfo></ResonatorInfo>;
            break
        case "weapons":
            section = <WeaponInfo></WeaponInfo>;
            break
        default:
            section = null;
    };
    return (
        <div className="interface">
            <div className="flex-container">
                {section}
            </div>
        </div>
    );
}


export default Interface;